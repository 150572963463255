import { createRouter, createWebHashHistory, createWebHistory, RouteRecordName, Router } from 'vue-router'
import type { App } from 'vue';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getUserInfo } from "~/utils/auth";
const { VITE_HTTP_ENV: env } = import.meta.env;

// import Setting from '~/utils/setting';
import { loginDuration } from '~/utils/loginDuration';

const { VITE_WEB_FIRST_LEVEL_ROUTING: webFirst } = import.meta.env;
const routerHistory = import.meta.env.MODE === 'dev'? createWebHistory(): createWebHistory(webFirst as string)
// const routerHistory = createWebHashHistory() //带 # 号的配置

const prod_router = createRouter({
  history: routerHistory, //路由模式
  routes: [
    {
      path: "/",
      redirect: '/copywriting'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('~/views/Login/index.vue'),
      meta: { title: '登录' }
    },
    {
      path: "/:catchAll(.*)",
      redirect: '/copywriting'
    },
    {
      path: '/oauth',
      name: 'oauth',
      component: () => import('~/views/Login/oauth.vue'),
      meta: { title: '认证' }
    },
    {
      path: '/link',
      name: 'link',
      component: () => import('~/views/Login/link.vue'),
      meta: { title: '认证' }
    },
    {
        path: '/',
        name: '首页',
        component: () => import('~/layout/Main.vue'),
        redirect: { name: '首页' },
        meta: { title: "首页" },
        children: [
          {
            name: '文案',
            path: '/copywriting',
            component: () => import('~/views/Copywriting/index.vue'),
            meta: { title: '文案' },
          },
          {
            name: '翻译',
            path: '/translate',
            component: () => import('~/views/Translate/index.vue'),
            meta: { title: '翻译' },
          },
          {
            name: '提问',
            path: '/chat',
            component: () => import('~/views/Chat/index.vue'),
            meta: { title: '提问' },
          },
          {
            name: '批量任务',
            path: '/task',
            component: () => import('~/views/Task/index.vue'),
            meta: { title: '批量任务' },
          }
        ],
    }
  ]
})

const sit_router = createRouter({
  history: routerHistory, //路由模式
  routes: [
    {
      path: "/",
      redirect: '/copywriting'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('~/views/Login/index.vue'),
      meta: { title: '登录' }
    },
    {
      path: "/:catchAll(.*)",
      redirect: '/copywriting'
    },
    {
      path: '/oauth',
      name: 'oauth',
      component: () => import('~/views/Login/oauth.vue'),
      meta: { title: '认证' }
    },
    {
      path: '/link',
      name: 'link',
      component: () => import('~/views/Login/link.vue'),
      meta: { title: '认证' }
    },
    {
        path: '/',
        name: '首页',
        component: () => import('~/layout/Main.vue'),
        redirect: { name: '首页' },
        meta: { title: "首页" },
        children: [
          {
            name: '词汇云',
            path: '/wordcloud',
            component: () => import('~/views/Home/index.vue'),
            meta: { title: '词汇云' },
          },
          {
            name: '评论排名',
            path: '/comment_rank',
            component: () => import('~/views/CommentRank/index.vue'),
            meta: { title: '评论排名' },
          },
          {
            name: '品牌声量',
            path: '/buzz',
            component: () => import('~/views/Buzz/index.vue'),
            meta: { title: '品牌声量' },
          },
          {
            name: '知识问答',
            path: '/knowledge',
            component: () => import('~/views/Knowledge/index.vue'),
            meta: { title: '知识问答' },
          },
          {
            name: '智能客服',
            path: '/chatassistant',
            component: () => import('~/views/chatAssistant/index.vue'),
            meta: { title: '智能客服' },
          },
          {
            name: '推荐',
            path: '/recommend',
            component: () => import('~/views/Recommend/index.vue'),
            meta: { title: '推荐' },
          },
          {
            name: '文案',
            path: '/copywriting',
            component: () => import('~/views/Copywriting/index.vue'),
            meta: { title: '文案' },
          },
          {
            name: '翻译',
            path: '/translate',
            component: () => import('~/views/Translate/index.vue'),
            meta: { title: '翻译' },
          },
          {
            name: '提问',
            path: '/chat',
            component: () => import('~/views/Chat/index.vue'),
            meta: { title: '提问' },
          },{
            name: '图片生成',
            path: '/image',
            component: () => import('~/views/ImageGeneration/index.vue'),
            meta: { title: '图片生成' },
          },
          {
            name: '批量任务',
            path: '/task',
            component: () => import('~/views/Task/index.vue'),
            meta: { title: '批量任务' },
          },
          {
            name: '词汇设置',
            path: '/setting_word',
            component: () => import('~/views/Setting/setVocabulary.vue'),
            meta: { title: '详情' }
          }
        ],
    }
  ]
})

const uat_router = createRouter({
  history: routerHistory, //路由模式
  routes: [
    {
      path: "/",
      redirect: '/chatassistant'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('~/views/Login/index.vue'),
      meta: { title: '登录' }
    },
    {
      path: "/:catchAll(.*)",
      redirect: '/chatassistant'
    },
    {
      path: '/oauth',
      name: 'oauth',
      component: () => import('~/views/Login/oauth.vue'),
      meta: { title: '认证' }
    },
    {
      path: '/holding',
      name: 'holding',
      component: () => import('~/views/Login/holding.vue'),
      meta: { title: 'Holding' }
    },
    {
      path: '/link',
      name: 'link',
      component: () => import('~/views/Login/link.vue'),
      meta: { title: '认证' }
    },
    {
        path: '/',
        name: '首页',
        component: () => import('~/layout/Main.vue'),
        redirect: { name: '首页' },
        meta: { title: "首页" },
        children: [
          {
            name: '知识问答',
            path: '/knowledge',
            component: () => import('~/views/Knowledge/index.vue'),
            meta: { title: '知识问答' },
          },
          {
            name: '智能客服',
            path: '/chatassistant',
            component: () => import('~/views/chatAssistant/index.vue'),
            meta: { title: '智能客服' },
          },
        ],
    }
  ]
})

export const router = env === 'UAT'? uat_router:(env === 'PROD'?prod_router:sit_router)

router.beforeEach(async (to, from, next) => { 
  // 开启进度条
  const userInfo = getUserInfo() as any
  const whiteList = ['link', 'oauth', '401', '403', 'holding'];
  NProgress.start();
  if (to.name === 'login') {
    next()
    return false;
  } else if (!userInfo && !whiteList.includes(to.name as string)) {
    next({path: '/login'});
  } else {
    // 12小时
    if (loginDuration()) {
      next({
        path: '/login'
      })
      return
    }
    next()
  }
})

router.afterEach((to,from) => {
  // 关闭进度条
  NProgress.done();
})

// 配置路由器
export function setupRouter(app: App<Element>) {
  app.use(router);
}
