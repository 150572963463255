export default {
  message: {
    home: 'ホーム',
    login: 'ログイン',
    accountLogin: 'アカウントログイン',
    pleaseEnterAccountName: 'アカウント名を入力してください',
    pleaseEnterYourPassword: 'ログインパスワードを入力してください',
    exitSystem: 'システムを終了する',
    reminder: '暖かいヒント',
    exitSystemTips: 'システムを終了することを確認しますか？',
    pleaseEnterNamePassword: '正しいユーザー名とパスワードを入力してください',

    // menu
    wordCloud: '語雲',
    commentRanking: 'コメントランキング',
    setup: '設定',
    setVocabulary: '用語の設定',

    //詞雲
    selectTime: 'まず期間を選択してください',
    wordcloudClickSearchGetData: '検索をクリックして,データを取得してください',
    wordcloudNoDataFound: 'データが検索されていません',
    wordcloudHeadwordSymbols: '中心語に特殊な記号が含まれているので,先に削除してください',
    wordcloudNotSupportSymbols: '特殊記号の入力はサポートされておらず,中国語,英語,数字しか入力できません',
    wordcloudHeadword: '中心語',
    wordcloudPleaseFillOut: '記入してください',
    wordcloudPlatform: 'プラットフォーム',
    wordcloudPleaseChoose: 'を選択してください',
    wordcloudSelectAll: 'すべて選択',
    wordcloudBrand: 'ブランド',
    wordcloudPeriod: '時間帯',
    wordcloudStartingTime: '開始時間',
    wordcloudEndTime: '終了時間',
    wordcloudNumberWords: '単語数',
    reset: 'リセット',
    search: '検索',
    keywords: 'キーワード',

    // 评论
    ECProductIDsTips: '入力されたidsをカンマで区切り',
    ECProductIDsTipsReg: '入力されたidsをカンマで区切った数字のみを入力するidをサポートする',
    new: '新規追加',
    filterWord: 'フィルタリング語',
    username: 'ユーザー名',
    date: '日付',
    actions: '操作',
    delete: '削除',
    addFilter: 'フィルタリング語の追加',
    "confirm": '確認',
    "cancel": 'キャンセル',
    deleteConfirm: '削除確認',
    deleteConfirmTips: '本当に削除しますか？',
    clickQueryData: '検索ボタンをクリックしてデータを検索する',
    original: '原文',
    heat: '熱',
    filter: '公式アカウントを除外',
    details: '詳細を表示するにはクリックしてください',
    comment: 'コメント',
    sort: '分類',
    contact: '連絡して',
    numLimit: '1-100の間で数値を選択してください',
    placeholderNum: '単語の数を入力してください',
    buzz: 'ブランドボリューム',
    productCode: '製品コード',
    brand: 'ブランド',
    productDesc: '製品説明',
    category: '品物',
    SBU: 'SBU',
    year: '年',
    season: '季節',
    gender: '性別',
    color: '色',
    colorGroup: 'カラーグループ',
    score: '得点',
    recommendedNumber: '推奨個数',
    intelligentRecommendation: 'インテリジェント推奨',
    selectProduct: '少なくとも1つの製品を選択してください',
    recommend: '推奨',
    pleaseInput: '入力してください',
    copywriting: '文案',
    //文案
    activityContent: '基本内容',
    productName: '製品名',
    productNamePlaceholder: '製品名の説明',
    eventTheme: 'テーマ',
    eventThemePlaceholder: 'キャンペーン、イベントなど',
    productFeatures: '製品特徴',
    productFeaturesPlaceholder: 'その他の製品特徴（50文字以内）',
    targetAudience: 'ターゲット',
    populationAge: '対象年齢',
    populationAgePlaceholder: '数字、キーワードでも入力可',
    crowdInterests: '対象シーン',
    crowdInterestsPlaceholder: '興味、趣味など（50文字以内）',
    otherFeatures: 'その他の特徴',
    otherFeaturesPlaceholder: '追加情報（50文字以内）',
    articleRequirements: 'コピーのスタイル',
    copywritingTone: '文章スタイル',
    wordNumber: '文字数',
    wordNumberPlaceholder: '最大文字数（最大1000文字）',
    deliveryChannels: '導線',
    generateContent: 'コンテンツを生成',
    generateTokenNumber: 'token数を生成',
    modificationComments: '結果の修正',
    modificationCommentsPlaceholder: '入力制限は100ワード以内',
    adjustingContent: 'コンテンツの調整',
    lively: '活発',
    amusing: '面白い',
    official: '公式',
    polite: '丁寧',
    casual: 'カジュアル',
    introverted: '内収束',
    passionate: '情熱',
    literary: '文芸',
    solemn: '荘重',
    male: '男',
    female: '女',
    unlimited: '無制限',
    copywritingTips: '会社の機密情報を漏らさないでください。使用中に問題がある場合は、右上のボタンをクリックして問題を報告してください。',
    copywritingTips2: 'いかなる場合も従業員、請負業者、消費者などの個人情報を公開はしないでください。',
    copywritingTips3: 'VF知的財産権をアップロードしたり、既存のVF知的財産権を使用して新規で作成したりしないでください。',
    redbook: 'RedBook',
    weibo: 'Weibo',
    officialAccount: 'WeChat Official Account',
    textMessage: 'Text Message',
    wecom: 'WeCom',
    advertisement: 'Advertisement',
    shortvideo: 'Short Video',
    creativeWriting: 'Creative Writing',
    instagram: 'Instagram',
    facebook: 'Facebook',
    livestream: 'Live Stream',
    waitingMessage: 'ファイルを生成しています',

    // 问题反馈
    feedbackButton: 'フィードバック',
    feedbackPlaceholder: '使用中に発生した問題、または必要な機能を入力してください。受け取ったらできるだけ早く処理します',
    select: '選択してください',
    "submitNow": "送信",
    getAuthenticationUrl: "認証アドレスを取得します",
    customLogin: "ユーザーの認証中",
    authenticateFail: "ログインページに戻る許可に失敗しました",
    SEOOpti: 'SEO最適化',
    hotWords: 'キーワード',
    seoHotWordsPlaceholder: 'キーワードをカンマで区切ってください',
    refPicture: '参考画像',
    translate: '翻訳',
    translateInputPlaceholder: 'テキストを入力',
    translateFromLanguage: '原言語',
    translateToLanguage: '目標言語',
    translateTone: 'スタイル',
    translateAuto: '自動検出',
    translateDocUpload: 'ドキュメントをアップロードしてください。対応フォーマットは.pptx/.docx/.pdfです。',
    translateDocDownload: 'ドキュメントの翻訳履歴',
    translateDocUploadBt: 'アップロード',
    translateDocDownloadBt: '記録',
    noDate: 'データなし',
    question: 'に質問',
    questionInputPlaceholder: 'にゅうりょくもんだい',
    imageInputPlaceholder: '説明の入力',
    "downloadAll": "すべてダウンロード",
    imageWaitingMessage: '画像を生成しています、少々お待ちください',
    downloadImages: '画像のダウンロード',
    image: '画像',
    askQuestion: 'に質問',
    imageGeneration: '画像生成',

    batchCopyGenerationTemplate: '一括ファイル生成テンプレート',
    templateDownload: 'テンプレートのダウンロード',
    upload: 'アップロード',
    generateTemplatesBatchTranslation: '一括翻訳生成テンプレート',
    batchTips:'定期バッチタスクは1年間保存されますので、お早めにダウンロードしてください。',
    taskName: 'タスク名',
    filePath: 'ファイルパス',
    number: '',
    taskTyps: 'タスク・タイプ',
    creator: '作成者',
    creationTime: '作成時間',
    taskStatus: 'タスクステータス',
    download: 'ダウンロード',
    refresh: 'リフレッシュ',
    pleaseEnterName: '名前を入力してください',
    uploadCopywriting: 'ファイルをアップロードする',
    uploadTranslation: '翻訳のアップロード',
    batchTasks: '一括タスク',
    choose: 'せんたく',
    translation: '翻訳',
    inProgress: '進行中',
    error: 'エラー',
    completed: '完了',

    knowledge: '知識問答',
    searchResult: '関連する結果を見つけました',
    relatedContentLink: '関連コンテンツの元の文',
    searchSummary: '以下が回答です',

       //Chat Assistant
       chatAssistant:'スマートカスタマーサポート',
       send:'送信する',
      topic:"テーマ",

       chatnotice1:'新しい会話が開始されました',
       chatnotice2:'前の会話内容は引用されません',
  }
}